import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import logo from "../../assets/images/studUniform.png";
// import logo from '../../assets/images/school.png'
// import background from "../../../assets/images/login_back.jpg"; studUniform
import man from '../../assets/images/project/user.png'
import 'react-toastify/dist/ReactToastify.css';
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { schoolSignIn } from 'src/constants';
// import SweetAlert from "sweetalert2";


const schema = yup
  .object()
  .shape({
    userName: yup.string().required('Username is required !'),
    password: yup.string().required('Password is required !'),
  })
  .required()

function AppLogin() {
  const [value, setValue] = useState(localStorage.getItem('profileURL' || man))
  let navigate = useNavigate()

  useEffect(() => {

    if (value !== null) localStorage.setItem('profileURL', value)
    else localStorage.setItem('profileURL', man)
  }, [value])

  useEffect(() => {
    
  }, [])


  const { register, handleSubmit, control, formState, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  }) // initialise the hook

  let { errors } = formState

  const onSubmit = (data) => {
  
    // if(data !== ''){
    //   localStorage.setItem(
    //     "access",
    //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjc2MzU0ODY3LCJpYXQiOjE2NzYyNjg0NjcsImp0aSI6Ijg5YTI1YTRkMzNhMTQ2OWM4MmVmMmY3MDkxNWFmNzNkIiwidXNlcl9pZCI6NDB9.qBa8Go3ogi1atXc3CBhB-EGjUQxTIzDRNRpSd4L0nlU"
    //   );
    //   navigate(`/sus/schoolmaster`)
    // }

    if (data !== "") {
      console.log("data", data);

      const apiUrl = `${process.env.REACT_APP_API_URL + schoolSignIn}`;
      // console.log("apiUrl", apiUrl);

      axios
        .post(apiUrl, data)
        .then((res) => {
          console.log("res", res);

          // setting token to localstorage
          localStorage.setItem("access", res.data.access_token);
          localStorage.setItem("TokenType", res.data.token_type);
          localStorage.setItem("Username", res.data.userName);
          localStorage.setItem("Role", res.data.role.role);

          if (res.status === 200) {
            toast.success("Login Successfully !", {
              autoClose: 2000,
            });
            // navigate(`/sus/schoolmaster`)

            if(res.data.role.role === 'admin'){
              navigate(`/sus/schoolmaster`)
            } else if(res.data.role.role === 'user'){
              navigate(`/sus/userDashboard`)
            }
           
          }
        })
        .catch((err) => {
          console.log("err", err);
          console.log("err.response", err.response);
          if (err.response.status === 401) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          } else {
            toast.error("Something wents wrong, plese try again !", {
              autoClose: 5000,
            });
          }
        });
    } else {
      errors.showMessages();
    }

  };

  return (
    <React.Fragment>
      <div className="app_login" style={{ marginTop: '50px' }}>
        <div className="container">
          <div className="row ">
            <div className="col-md-12 ">
              <div className="auth-innerright">
                <div className="authentication-box ">
                  <div className="text-center">
                    <img src={logo} width={'200px'} height={'180px'} alt="" />
                    <h4 className="pt-2">{"STUDENT UNIFORMS"}</h4>
                  </div>
                  <div className="card mt-4 shadow-lg mx-auto" style={{ maxWidth: '500px' }}>
                    <div className="card-body">
                      <div className="text-center">
                        <h4>{'Sign In'}</h4>
                        <h6>{'Enter Username & Password'} </h6>
                        {/* <h6 className="f-22">{"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"} </h6> */}
                      </div>
                      <form>
                        <div className="form-group">
                          <label className="col-form-label fw-bold pt-0">{'Username'}</label>
                          <input
                            className="form-control"
                            type="text"
                            name="userName"
                            // value={email}
                            // onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter username"
                            {...register('userName')}
                          />
                          <span className="text-danger">{errors.userName?.message}</span>
                        </div>
                        <div className="form-group">
                          <label className="col-form-label fw-bold">{'Password'}</label>
                          <input
                            className="form-control"
                            type="password"
                            name="password"
                            // value={password}
                            // onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter password"
                            {...register('password')}
                          />
                          <span className="text-danger">{errors.password?.message}</span>
                        </div>

                        <div className="form-group form-row mt-4 mb-0 d-grid">
                          <button
                            className="btn btn-primary fw-bold"
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                          >
                            {'Sign In'}
                          </button>
                          <p className="mt-2 text-center mb-0" style={{ fontSize: '13px' }}>
                            Version {process.env.REACT_APP_VERSION}
                          </p>
                        </div>
                        {/* <ArrowLeftCircle className="mt-2 .text-primary" onClick={() => navigate(-1)}/> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AppLogin
