export const RefreshToken = "/api/token/refresh";

export const schoolSignIn = "api/token/";

// category master
// export const get_school_ms = "api/master/getSchoolMasters/";
export const get_category = "api/v2/master/getCategoryMaster/";
export const save_category = "api/master/saveCategoryMaster/";
export const update_category = "api/master/updateCategoryMaster/";
export const remove_category = "api/master/deleteCategoryMaster/";

// school master
// export const get_school_ms = "api/master/getSchoolMasters/";
export const get_school_ms = "api/v2/master/getSchoolMasters/";
export const save_school_ms = "api/master/saveSchoolMaster/";
export const update_school_ms = "api/master/updateSchoolMaster/";
export const remove_school_ms = "api/master/deleteSchoolMaster/";

// global item master
// export const get_globalItem_ms = "api/master/getGlobalItemsWithSchoolItem/";
export const get_globalItem_ms = "api/v2/master/getGlobalItems/";                  
export const get_globalItemWithOrderStatus = "api/v2/master/getGlobalItemsWithOrderStatus/";      // only for edit package cost api 4/7/23
export const save_globalItem_ms = "api/master/saveGlobalItems/";
export const update_globalItem_ms = "api/master/updateGlobalItems/";
export const remove_globalItem_ms = "api/master/deleteGlobalItems/";

// school item master
export const get_schoolItem = "api/v2/master/getSchoolItems/";
export const save_schoolItem = "api/master/saveSchoolItems/";
export const update_schoolItem = "api/master/updateSchoolItems/";
export const remove_schoolItem = "api/master/deleteSchoolItems/";

// user master
export const get_roles = "user/roles/";
export const get_user_ms = "user/users/";
export const save_user_ms = "api/user/";
export const update_user_ms = "api/updateUser/";
export const reset_userPassword = "api/resetPassword/";

// school student master
export const import_students = "api/v2/master/ImportFile/";
export const get_student_ms = "api/v2/master/getStudents/";
export const save_student_ms = "api/master/saveStudents/";
export const update_student_ms = "api/master/updateStudents/";
export const remove_student_ms = "api/master/deleteStudents/";


// school order master
// export const get_schoolOrders = "api/v2/order/getOrderMaster/";           // old api without batch detail
// export const get_schoolOrders = "api/v3/order/getOrderMaster/";           // old api with batch detail
export const get_schoolOrders = "api/v4/order/getOrderMaster/";              // new api with batch detail 4/7/23

// school order items
export const get_schoolOrderItems = "api/v2/order/getOrderItems/"; 

// school total order items
// export const get_schoolTotalOrderItems = "api/v2/order/getOrderItemCountsList/";    // old api
export const get_schoolTotalOrderItems = "api/v4/order/getOrderItemCountsList/";       // new api

// school measurement statistics
export const get_schoolOrderStats = "api/v2/order/getOrderStatistics/";
export const get_pendingOrders = "api/v2/order/getStudentListOrderNotPlaced/";

// school order statistics
export const get_schoolOrderCompleteStats = "api/v2/order/getOrderCompleteStatistics/";
export const get_stockPendingOrders = "api/v3/order/pendingOrderStockShortage/";

// store master
export const get_store_ms = "api/v2/transaction/getStoreMaster/"; 
export const save_store_ms = "api/v2/transaction/addStoreMaster/";
export const update_store_ms = "api/v2/transaction/updateStoreMaster/";
export const remove_store_ms = "api/v2/transaction/deleteStoreMaster/";

// transaction master
export const get_transaction_ms = "api/v2/transaction/getTransactionMaster/";
export const save_transaction_ms = "api/v2/transaction/addTransactionMaster/"; 
export const update_transaction_ms = "api/v2/transaction/updateTransctionMaster/";
export const remove_transaction_ms = "api/v2/transaction/deleteTransctionMaster/"; 

// Inventory transaction 
export const inwardStock_Template = "api/v2/transaction/InwardStockSchoolItemTemplate";
export const import_InwardStock = "api/v2/transaction/InwardStockSchoolItemUpload/";
export const get_InventoryTransaction = "api/v2/inventory/getInventoryTransaction/"; 
// export const save_InventoryTransaction = "api/v2/inventory/inventoryTransaction/";
export const save_InventoryTransaction = "api/v3/inventory/inventoryTransaction/";

// Inventory stock summary 
export const get_stockSummary = "api/v2/transaction/stockDetails/";                           // get list of orders with inward, outward and quantitiy
// export const check_orderFullfillment = "api/v2/stock/checkOrderfulfillment/";              // old api
export const check_orderFullfillment = "api/v3/stock/checkOrderfulfillment/";                 // new api
// export const order_Assigned = "api/v2/stock/orderStatusChangeAndStockUpdate/";             // old api
// export const order_Assigned = "api/v3/stock/orderStatusChangeAndStockUpdate/";             // old api
export const order_Assigned = "api/v5/stock/orderStatusChangeAndStockUpdate/";                // new api 4/7/23
// export const get_currentStockSummary = "api/v3/stock/getCurrentStockSummary/";                // get current stock summary
export const get_currentStockSummary = "api/v3/stock/getCurrentStockSummaryNew/";                // get current stock summary

// Package details
export const get_packageDetail = "api/v2/orders/orderpackagedetails/getOrderPackageDetails/";
export const save_packageDetail = "api/v2/orders/orderpackagedetails/addOrderPackageDetails/";
// export const update_packageDetail = "api/v2/orders/orderpackagedetails/updateOrderPackageDetails/";      // old api
export const update_packageDetail = "api/v3/orders/orderpackagedetails/updateOrderPackageDetails/";         // new api 4/7/23
export const remove_packageDetail = "api/v2/orders/orderpackagedetails/deleteOrderPackageDetails/";

// School batch details
// export const get_schoolOrderBatches = "api/v2/transaction/getOrderBatch/";         // old api 
export const get_schoolOrderBatches = "api/v3/transaction/getOrderBatch/";            // new api 4/7/23
// export const get_deliveryChallan = "api/v2/inventory/getDeliveryChallan/";         // old api
// export const get_deliveryChallan = "api/v3/inventory/getDeliveryChallan/";         // new api
export const get_deliveryChallan = "api/v4/inventory/getDeliveryChallan/";            // new api 4/7/23

// export const get_orderAndOrdersItems = "api/v2/order/getOrderMasterWithOrderItem/";     // old api
export const get_orderAndOrdersItems = "api/v3/order/getOrderMasterWithOrderItem/";        // new api 4/7/23
export const update_batchDetails = "api/v2/transaction/updateOrderBatch/";
export const update_DocketNumber = "api/v2/order/updateOrderDocketNumber/";

// Report
export const get_FinancialYear = "api/inventory/getfinanceYearMaster";
export const get_DailyReport = "api/transaction/getInventoryTransactionSummaryDaily";
export const get_MonthlyReport = "api/transaction/getInventoryTransactionSummaryMonthly";
export const get_YearlyReport = "api/transaction/getInventoryTransactionSummaryYearly";

// Stock required Order Completion
export const get_StockRequired = "api/v2/report/getStockRequired/";
export const get_STDwiseStockRequired = "api/v2/report/getStdWiseStockRequired/";

// Total batch invoice of School
export const get_totalBatchInvoice = "api/v3/order/batchListData/"
export const get_stdwiseInvoice = "api/v3/report/getStdWiseSchoolData/"

// Order edit and update school item api's
export const get_OrderForEdit = "api/v2/order/getStudentAndGlobalItemPkgData/"
export const get_OrderForUpdate = "api/v2/order/getCategoryAndSchooItem/"
export const update_OrderItem = "api/order/updateOrderItems/"




